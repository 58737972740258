
.stdInputField(){
  height: 42px;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border: 1px solid @primaryColor;
  font-family: @stdFont;
  font-size: @stdFontSize;
}

.halfSecondTransition(){
  -webkit-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -ms-transition: 0.5s ease-out;
  -o-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
}

.oneSecondTransition(){
  -webkit-transition: 1s ease-out;
  -moz-transition: 1s ease-out;
  -ms-transition: 1s ease-out;
  -o-transition: 1s ease-out;
  transition: 1s ease-out;
}

.interactionTransition(){
  -webkit-transition: 0.33s ease;
  -moz-transition: 0.33s ease;
  -ms-transition: 0.33s ease;
  -o-transition: 0.33s ease;
  transition: 0.33s ease;
}


.footerlink(){
  font-family: @stdFont;
  color: @white;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  &:hover, &:focus, &:active{
    color: @black;
  }
}