/**

  ALL BUTTON CLASSES


 */

// button's wrapper | type DIV
.button--wrapper{
  margin-bottom: 1rem;
}

// wrapper's modifier in case of given additional classes
.button--hasExtraClasses{}
// each additional class will appear as .button--hasExtraClass-%addClass%

// wrapper's modifier in case of external Link
.button--external{}

// the button itself | type A
.button, button{
  display: inline-block;
  padding: 1rem 2rem;
  background: @primaryColor;
  color: @white;
  font-size: @stdFontSize;
  cursor: pointer;
  border: 0;
  .interactionTransition();

  &:active,
  &:hover{
    background: @secondaryColor;
  }

  &.button--slim{
    padding: 0.33rem 0.66rem;
    font-size: 0.75rem;
    margin-right: 1.5rem;
    background: @mediumGrey;

    &:active,
    &:hover{
      background: @secondaryLight;
    }
  }
}
// each additional class will appear after button class on the link
