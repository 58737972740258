// disable grids standard padding for divs inside .container
.container > .navigation{
  padding: 0;
}



// Standard Navigation Styles
.navigation{

  .menu--mainnav{
    display: table;
    width: 100%;

    @media @momentToShowTheHamburger{
      padding-top: 2rem;
    }

    li{
      float: left;
    }

    // li.item--hasChild
    .item--hasChild{
      position: relative;

      & > a{
        float: left;
      }
    }

    a{
      display: block;
      height: 120px;
      box-sizing: border-box;
      font-family: @linkFont;
      padding: 70px 1rem 0 1rem;
      font-size: 1.125rem;
      color: @linkColor;

      .interactionTransition();

      &:hover{
        color: @black;
      }

      @media @tabP{
        font-size: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    // li.active
    .active{

      & > a{
        font-weight: 500;
      }
    }

    .trigger--submenu{
      float: right;
      margin-top: 66px;
      color: @black;
      width: 30px;
      height: 44px;
      border: 0;
      background: none;
      text-align: left;
    }



    // Submenu Styles
    .item--submenu{
      position: absolute;
      z-index: 999;
      top: 100%;
      left: 0;
      width: auto;
      display: table;
      background: @white;
      white-space: nowrap;
      visibility: hidden;
      -webkit-transform: translate(0, 1.5rem);
      -moz-transform: translate(0, 1.5rem);
      transform: translate(0, 1.5rem);
      opacity: 0;
      .interactionTransition();

      li{
        display: table;
        width: 100%;
        border-bottom: 1px solid @black;
      }
      a{
        text-align: left;
        padding: 0 1rem;
        height: auto;
        line-height: 44px;

        &:hover, &:active, &:focus{
          background: @black;
          color: #fff;
        }
      }
    }

    .submenu--in .item--submenu,
    .submenu--fixed .item--submenu{
      z-index: 999;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}



// Smaller Navigation Bar in case of having scrolled down
.scrolled .navigation .menu--mainnav{
  a{
    height: 50px;
    padding-top: 12px;
  }

  .trigger--submenu {
    margin-top: 6px;
  }
}






// hide subnav trigger in case of beeing able to hover
.can--hover .navigation .menu--mainnav .trigger--submenu{display: none;}










@media @smL{

  // Mobile Menu - slided out

  header .header--navigation{

    position: fixed;
    top: 80px;
    left: 0;
    width: 85%;
    height: ~"calc(100vh - 80px)";
    display: block;
    z-index: 999;
    -webkit-transform: translate3d(-101%,0,0);
    -moz-transform: translate3d(-101%,0,0);
    -ms-transform: translate3d(-101%,0,0);
    -o-transform: translate3d(-101%,0,0);
    transform: translate3d(-101%,0,0);
    background: @white;
    .interactionTransition();

    .menu--mainnav{
      li{
        width: 100%;
        display: block;

        a{
          display: block;
          padding: 0 1rem;
          line-height: 44px;
          height: 44px;
          width: 100%;
        }


        &.item--hasChild{

          display: table;
          width: 100%;

          a{
            width: 80%;
            float: left;
          }

          .trigger--submenu{
            width: 20%;
            height: 44px;
            float: right;
            margin-top: 0;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
  }

  // Mobile Menu - slided in
  .off-canvas--in{

    header .header--navigation{
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      -o-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
    }

  }

  // second level slides
  .navigation .menu--mainnav{
    position: relative;

    a{border: 0;}

    .item--hasChild{
      position: static;
    }


    .item--submenu{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      max-width: 80%;
      background: #bbb;
      height: ~"calc(100vh - 80px)";
      bottom: 0;
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate3d(-102%,0,0);
      -moz-transform: translate3d(-102%,0,0);
      -ms-transform: translate3d(-102%,0,0);
      -o-transform: translate3d(-102%,0,0);
      transform: translate3d(-102%,0,0);

      li a{
        text-align: left;
      }
    }
  }

}





