main ul li{

  margin: 0 0 1rem 0;
  padding: 0 0 0 1.5rem;
  position: relative;

  // arrows for list items
  &::before{
    position: absolute;
    height: 0;
    width: 0;
    top: 0.4rem;
    left: 0;
    border: 5px solid transparent;
    border-left: 5px solid @stdTextColor;
    content: "";
  }
}

main ol li{
  margin: 0 0 1rem 0;
  padding: 0 0 0 0rem;
  position: relative;
  list-style-type: decimal-leading-zero;
}