.date--selection{

  //margin-bottom: 4rem;

  .date--selection-inner{
    background: @tertiaryColor;
    padding: 2rem 2rem 4rem 2rem;
    max-width: 66%;
    position: relative;

    @media @smL{
      box-sizing: border-box;
      width: 100%;
      max-width: unset;
    }

    .container{
      max-width: unset;
      width: auto;
    }


    button{
      /*position: absolute;
      bottom: -1rem;
      left: 45%;*/
      width: 160px;
      border: 0;
      background: @secondaryColor;
      color: @white;
      font-size: @stdFontSize;
      padding: 0.75rem 2rem;
      cursor: pointer;
      .interactionTransition();

      &:hover,
      &:active{
        background: @primaryColor;
      }

      &[disabled="disabled"]{
        cursor: not-allowed;
        background: @mediumGrey;
        &:hover,
        &:active{
          background: @mediumGrey;
        }
      }
    }
  }


  // wrapper around the date selection inputs
  .select--date{

    .contentElement{
      display: flex;

      @media @tabP{
        flex-direction: column;
      }
    }

  }


  .select--start-date{
    margin-right: 2rem;

    @media @smL{
      margin-right: 0;
    }

    input{
      .stdInputField();
      padding-left: 4rem;
      min-width: 14rem;
      letter-spacing: 2px
    }
  }

  .select--date-range{
    width: auto;
    display: flex;
    align-items: center;
    margin-right: 2rem;

    @media @smL{
      margin-right: 0;
    }

    .select--date-range-indicator{
      width: 24px;
      height: 24px;
      border: 1px solid @primaryColor;
      display: inline-block;
      position: relative;

      &::after{
        position: absolute;
        top: 3px;
        left: 3px;
        content: "";
        displaY: block;
        background: @white;
        width: 18px;
        height: 18px;
      }
    }

    p{
      margin: 0 0 0 1rem;
    }

    &.range--selected{

      .select--date-range-indicator{
        &::after{
          background: @primaryColor;
        }
      }
    }


    @media @tabP{
      margin: 2rem 0;
    }
  }

  .select--end-date{
    width: 33%;
    display: none;

    @media @smL{
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
    }

    input{
      .stdInputField();
      padding-left: 4rem;
      min-width: 14rem;
      letter-spacing: 2px
    }
  }
}