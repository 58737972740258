.row{
  padding: calc(@rowStdMargin + 2rem) 0 calc(@rowStdMargin - 2rem) 0;

  @media @smL{
    padding: @rowStdMargin 0 calc(@rowStdMargin - 4rem) 0;
  }

  .row{padding: 0;}

  // for colored rows, switch to padding instead of margin
  &.has--primary-color,
  &.has--secondary-color,
  &.has--tertiary-color{
    padding: @rowStdMargin 0;
    margin: 0;
  }
}

.admin .contentElement{
  position: relative;
}



/**

    These classes can be set in BE via Dropdown.
    You have to know, which color corresponds to which class
    and set an alternative Text color, if necessary.

 */
.has--primary-color{
  background: @primaryColor;
  color: @white;
}
.has--secondary-color{
  background: @secondaryLight;
  color: @white;
}
.has--tertiary-color{
  background: @tertiaryColor;
}





@media @smL{

  .page--produkte #ce-2 .content10050{
    .content100C{
      order: 3;
    }
    .content50R{
      order: 2;
    }
    .content50L{
      order: 1;
    }
  }


  .page--team #ce-2 .content50L,
  .page--team #ce-2 .content50R{
    margin-bottom: 3rem;
  }
}