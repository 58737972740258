
#openAddressForm{
  margin-left: 1rem;

  @media @smL{
    margin-left: 0;
  }
}

.form--row{
  margin-bottom: 2rem;
  max-width: 66%;

  @media @tabP{
    max-width: 100%;
  }

  &.form--row-double{
    display: flex;
    width: 100%;

    div:first-child{
      margin-right: 1rem;

      @media @tabP{
        margin-right: 0;
      }
    }

    div:last-child{

      label{
        box-sizing: border-box;
        padding-right: 1rem;
        text-align: right;

        @media @smL{
          width: 50px;
        }
      }
    }

    @media @tabP{
      .form--row-short{
        label{
          width: 80px;
          margin-left: 0;
          margin-right: 0;
          text-align: left;
        }
        input{
          max-width: 80px;
        }
      }

      .form--row-long{
        input{
          max-width: unset;
          width: auto;
        }
      }
    }
  }



  label{
    display: inline-block;
    width: 120px;
  }

  input[type='text']{
    padding: 0.5rem;
    border: 1px solid @mediumGrey;
    font-family: @stdFont;
    font-size: 0.875rem;
    width: 320px;
    max-width: 100%;
    box-sizing: border-box;
    .interactionTransition();
  }

  #o_number,
  #o_plz{
    width: 80px;
  }

  &.form--checkbox{

    input{
      max-width: 2rem;
    }
    label{
      width: auto;
      display: inline;
    }
  }


  .message{
    font-size: 0.75rem;
    padding: 0.33rem 0 1rem 0;
    margin-left: 120px;

    @media @smL{
      margin-left: 0;
    }
  }
  .message--alert{
    color: @warningColor;
  }
}



.invalid{
  input[type='text']{
    border: 1px solid @warningColor;
  }
}




.message.message--success {
  padding: 1rem;
  margin: 2rem 0 2rem 0;
  background: fade(@secondaryColor, 20%);
  color: @secondaryColor;
  border: 1px solid @secondaryColor;
  max-width: 71%;
  box-sizing: border-box;

  @media @smL{
    max-width: unset;
  }
}



.rentals--calc-price{
  padding: 2rem 1rem;

  @media @smL{
    background: @lightGrey;
    padding: 1rem;
  }

  // hide top price on desktops
  &.calc-price-top{
    display: none;

    @media @smL{
      display: block;
    }
  }
}
.showPriceHere{
  color: @black;
  font-size: 2rem;
  margin-left: 1rem;
}
.euroSign{
  font-size: 2rem;
  color: @black;
}
.price--hints{
  color: @mediumGrey;
}


#off-possible {
  padding: 0 1rem 2rem 1rem;
  margin-top: -1rem;

  @media @smL {
    padding: 0 1rem 1rem 1rem;
  }

  &.hidden{
    display: none;
  }
}


#addressData{
  display: none;

  &.address-fields-visible{
    display: block;
    padding: 0 1rem;
    margin-top: 3rem;

    @media @tabP{
      padding: 0;
    }
  }
}


// HONEYPOT: The unused website field:
.form--hidden{
  display: none;
}