main{
  min-height: 600px;
}

.teaser--row{

  &.row{
    @media @smL{
      margin-bottom: 5rem;
    }
  }


  .content33R{

    position: relative;
    min-height: 5rem;

    .contentElement{
      position: absolute;
      top: 2rem;
      left: 0;
      right: 0;
      height: auto;
      padding: 3rem;
      background: @primaryColor;
    }
  }
}