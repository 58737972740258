header{
  padding-top: 120px;
  .interactionTransition();

  .header{
    position: fixed;
    z-index: 555;
    width: 100%;
    top: 0;
    left: 0;
    height: 120px;
    background: @white;
    margin: 0;
    padding: 0;
    .interactionTransition();

    .container{
      height: 100%;
    }
  }

  .header--identity{
    width: 20%;

    img{
      height: 100px;
      margin-top: 10px;
      .interactionTransition();
    }
  }

  .header--navigation{
    width: 70%;
    margin-left: 10%
  }




  // Mobile Styles
  @media @tabP{


      padding-top: 80px;

    .header{
      height: 80px;
    }
    .header--identity{
      width: 50%;

      img{
        max-height: 80px;
        margin-top: 0;
      }
    }

    .header--navigation{
      margin-left: 0;
    }
  }
}


.scrolled{

  header{

    padding-top: 50px;

    .header{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      border-bottom: 2px solid @white;
      box-shadow: 0 0 2px rgba(0,0,0,0.6);
      z-index: 444;

      @media @smL{
        height: 80px;
      }
    }

    .header--identity{

      img{
        margin-top: 0;
        height: 50px;
      }
    }
  }
}



.homeheader{

  padding-top: 0;
  padding-bottom: 0;

  .content100C{

    position: relative;

    .image{
      margin-bottom: 0;

      img{
        display: block;
        margin: 0;
      }
    }

    .headline--wrapper{
      position: absolute;
      top: 70%;
      left: 50%;
      max-width: @maxwidth;
      margin-left: (@maxwidth / -2);
      margin-right: auto;
      z-index: 380;

      &::after{
        position: absolute;
        top: -2rem;
        left: -2rem;
        content: "";
        display: block;
        background: @secondaryColor;
        width: 100%;
        height: 100%;
      }

      h1{
        padding: 3rem 4rem;
        background: @primaryColor;
        color: @white;
        font-size: 3rem;
        font-weight: 400;
        position: relative;
        z-index: 400;
        margin-bottom: 0;
      }


      @media @smL{
        position: static;
        width: 100%;
        max-width: 100%;
        margin: auto;

        h1{
          padding: 2rem 1rem;
          font-size: 2rem;
        }

        &::after{
          display: none;
        }
      }

    }
  }
}