/* -----------------------------------------------------------

NORMALISIERUNG DER ELEMENTE

----------------------------------------------------------- */
html{
  font-size: 16px; /* root-em Ausgangswert */
}

body{
  margin: 0;
}



/* Abstände allesamt auf Null setzen */

p, ul, li{
  margin: 0;
  padding: 0;
  list-style-type: none;
}
h1, h2, h3, h4, h5, h6{
  line-height: 1.1;
  margin: 0 0 0.4rem 0;
  padding: 0;
}


/* Standard-Verhalten der Links nullen */

a, a:hover, a:active{
  text-decoration: none;
}
a:focus{
  outline: 0;
  text-decoration: none;
}
input[type=email],
input[type=password],
input[type=date],
input[type=number],
input[type=text],
textarea{
  border-radius: 0;
  padding: 0.35em 0.85em;
  border: 1px solid @black;
  box-shadow: 0 0 0 rgba(0,0,0,0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button, input[type="submit"]{
  border: 0;
  background: @black;
  color: @white;
  border-radius: 0;
  padding: 0;
  box-shadow: 0 0 0 rgba(0,0,0,0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:hover, &:active, &:focus, &::-moz-focus-inner{
    outline: none;
    border: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}

figure,
picture{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
figure img,
picture img{
  display: block;
  width: 100%;
  height: auto;
}
address{
  font-weight: 400;
  font-style: normal;
  margin: 0;
}