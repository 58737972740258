//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,500i,700');
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');


/**
      COLOR standard settings
 */

@stdTextColor:        #343434;
@mediumGrey:          #999;
@black:               #000;
@white:               #fff;
@lightGrey:           #eee;
@warningColor:        #c70c30;
@linkColor:           @primaryColor;

// IMPORTANT!! Define the same colors here like in Edith's BE!
@primaryColor:        #003366;
@secondaryColor:      #37a200;
@secondaryLight:      #70c743;
@tertiaryColor:       #ececec;




/**
      TYPOGRAPHY standard settings
 */

@stdFont: "Fira Sans", Helvetica, Calibri, sans-serif;
@linkFont: "Fira Sans", Helvetica, Calibri, sans-serif;   // linkFont used for main nav items

@h1size:          2rem;
@h2size:          1.875rem;
@stdFontSize:     1rem;
@stdLineHeight:   1.5;





/**
      LAYOUT standard settings
 */

@maxwidth: 1280px;

@rowStdMargin:  7rem;

@momentToShowTheHamburger:  @tabP;

@tabL:        ~"only screen and (max-width: 1279px)";     // tabL == tablet in Landscape
@tabP:        ~"only screen and (max-width: 853px)";      // tabL == tablet in Portrait
@tabP_only:   ~"only screen and (max-width: 853px) and (min-width: 621px)";
@smL:         ~"only screen and (max-width: 620px)";      // smL == smartphone in Landscape
@smP:         ~"only screen and (max-width: 480px)";      // smP == smartphone in Portrait
@smX:         ~"only screen and (max-width: 384px)";      // smX == very small smartphones

/* usage:

   @media @smL{
      your-css: declarations;
   }

*/