.slide--inner{
  background: @white;
  display: flex;
  position: relative;

  .slide--image{
    width: 100%
  }

  .slide--caption{
    position: absolute;
    top: 10%;
    left: 2rem;
    height: 80%;
    width: 33%;
    box-sizing: border-box;
    padding: 3rem 4rem;
  }
}


// Prev / Next Arrows
.slick-prev{
  width: 100px;
  height: 50px;
  background: rgba(255,255,255,0.8);
  border: 0;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 333;
}
.slick-next{
  width: 100px;
  height: 50px;
  background: rgba(255,255,255,0.8);
  border: 0;
  position: absolute;
  bottom: -1px;
  left: 102px;
}