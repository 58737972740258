.website--footer{

  font-size: 0.875rem;
  background: @stdTextColor;
  color: @white;
  padding: @rowStdMargin 0;
  margin: 0;

  p{
    margin-bottom: 0.875rem;
  }
}



.footer--nav{

  a{
    .footerlink();
  }
}
