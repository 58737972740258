.product--selection{
  padding-top: 4rem;
}

.product--grid-headline{
  padding: 0 1rem;

  @media @smL{
    padding: 0;
  }
}

.grid-list-title{
  font-size: 1.33rem;
  padding: 0 1rem;

  @media @smL{
    padding: 0;
  }
}

.products--listing-grid{

  flex-flow: row wrap;
  display: flex;

  .product--item{
    //width: 33.333%;
    padding: 1rem;
    box-sizing: border-box;

    img{
      max-width: 100%;
      height: auto;
      display: block;
    }

    @media @tabP{
      flex-basis: 50%;
    }

    @media @smL{
      padding: 0;
      margin-bottom: 2rem;
    }
  }


  &.grid--4-col{

    .product--item {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25%;

      @media @tabP {
        flex-basis: 50%;
      }

      @media @smL {
        flex-basis: 100%;
      }
    }
  }

  &.grid--3-col{

    .product--item {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 33%;

      @media @tabP {
        flex-basis: 50%;
      }

      @media @smL {
        flex-basis: 100%;
      }
    }
  }


}



.product--selection-inner{
  margin-left: -1rem;
  margin-right: -1rem;

  @media @tabP{
    margin-left: 0;
    margin-right: 0;
  }
}